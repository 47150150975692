import React from "react";
import { Alert, AlertTitle, Box, Button, Container, Typography } from "@mui/material";
import { Form, useNavigate, useParams } from "react-router-dom";
import { apiClientHooks } from "../bootstrapping/InitApiClient";
import { Helmet } from "react-helmet-async";
import { queryClient } from "../bootstrapping/InitReactQuery";
import { paymentMethodTypeMapping } from "../utils/option-data";
import { schemas } from "../generated/api/client";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import SingleClickWaitingButton from "../components/SingleClickWaitingButton";
import { TextFieldElement } from "react-hook-form-mui";

const zFormSchema = schemas.EBulkApplicationPayment
type FormSchema = z.infer<typeof zFormSchema>

type ExternalPaymentFormProps = {
  application_id: string,
  price: number | undefined,
  payment_method: FormSchema['payment_method'],
  payment_reference_text?: React.ReactNode,
  buttonText: string,
}

const ExternalPaymentForm: React.FC<ExternalPaymentFormProps> = props => {
  const navigate = useNavigate()

  const payForEBulkApplicationByID = apiClientHooks.usePayForEBulkApplicationByID({
    params: {
      id: props.application_id,
    },
  })

  const onPayByExternal = async (payment_method: 'external_stripe_paymentlink' | 'external_creditcard' | 'external_other', payment_pence: number, payment_reference: string) => {
    if (!props.price) throw new Error('Price is required')
    await payForEBulkApplicationByID.mutateAsync({
      payment_method,
      payment_pence,
      payment_reference,
    })
    queryClient.invalidateQueries({ queryKey: apiClientHooks.getKeyByAlias('getEBulkApplicationByID') })
    queryClient.invalidateQueries({ queryKey: apiClientHooks.getKeyByAlias('getEBulkApplications') })
    queryClient.invalidateQueries({ queryKey: apiClientHooks.getKeyByAlias('getGlobalStats') })
    navigate(`/`)
  }

  // Build the Form
  const form = useForm<FormSchema>({
    resolver: zodResolver(
      zFormSchema
        .superRefine((value, context) => {
          // Ensure the payment_reference starts with "pi_"
          if (value.payment_method === 'external_stripe_paymentlink' && !value.payment_reference.startsWith('pi_')) {
            context.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Payment Reference must start with "pi_"',
              path: ['payment_reference'],
            })
          }
        })
    ),
    mode: 'onBlur',
    defaultValues: {
      payment_method: props.payment_method,
      payment_pence: props.price,
    },
  })

  // Build the output
  const textFieldElementCommonProps = {
    control: form.control,
    margin: "normal" as const,
    fullWidth: true,
  }

  return <>
    <Form method="post">
      {props.payment_reference_text}

      <TextFieldElement {...textFieldElementCommonProps} label="Payment Reference" name="payment_reference" />

      <Typography component="p" variant="body2" marginTop={2}>
        This is the amount of the total paid by the customer <strong>as a whole number in pennies</strong>, which relates to this application. Defaults to the amount expected for this application, and will usually be this number.
      </Typography>

      <TextFieldElement
        {...textFieldElementCommonProps}
        label="Payment Amount"
        name="payment_pence"
        type="number"
        inputProps={{ min: 0 }}
      />

      <SingleClickWaitingButton
        type="submit"
        fullWidth
        variant="contained"
        color={form.formState.isValid ? 'primary' : 'inherit'}
        onClick={async event => {
          const isValid = await form.trigger()
          if (!isValid) {
            console.warn("Form is not valid", form.formState.errors)
            event.preventDefault()
          } else {
            const values = form.getValues()
            if (
              values.payment_method !== 'external_stripe_paymentlink'
              && values.payment_method !== 'external_creditcard'
              && values.payment_method !== 'external_other') {
              console.warn("Payment method unexpected: ", values.payment_method)
              return
            }

            await onPayByExternal(values.payment_method, values.payment_pence, values.payment_reference)
          }
        }}
        sx={{ mt: 3, mb: 2 }}
      >
        {props.buttonText}
      </SingleClickWaitingButton>
    </Form>
  </>
}

export const Element: React.FC = () => {
  const navigate = useNavigate()

  const { application_id } = useParams<{ application_id?: string }>()
  if (!application_id) throw new Error('application_id is required')

  // Determine the cost of the application
  const usePriceForEBulkApplicationByID = apiClientHooks.usePriceForEBulkApplicationByID({ params: { id: application_id } })

  const appPricing = usePriceForEBulkApplicationByID.data

  const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState<string | undefined>(undefined)

  const choosePaymentMethod = (method: string) => {
    // set the selected payment method
    setSelectedPaymentMethod(method)
  }

  const payForEBulkApplicationByID = apiClientHooks.usePayForEBulkApplicationByID({
    params: {
      id: application_id,
    },
  })

  const onNoPaymentNecessary = async () => {
    if (!appPricing?.price) throw new Error('Price is required')
    await payForEBulkApplicationByID.mutateAsync({
      payment_method: 'none',
    })
    queryClient.invalidateQueries({ queryKey: apiClientHooks.getKeyByAlias('getEBulkApplicationByID') })
    queryClient.invalidateQueries({ queryKey: apiClientHooks.getKeyByAlias('getEBulkApplications') })
    queryClient.invalidateQueries({ queryKey: apiClientHooks.getKeyByAlias('getGlobalStats') })
    navigate(`/`)
  };

  const onPayByInvoice = async () => {
    if (!appPricing?.price) throw new Error('Price is required')
    await payForEBulkApplicationByID.mutateAsync({
      payment_method: 'invoice',
      payment_pence: appPricing.price.priceInPence,
    })
    queryClient.invalidateQueries({ queryKey: apiClientHooks.getKeyByAlias('getEBulkApplicationByID') })
    queryClient.invalidateQueries({ queryKey: apiClientHooks.getKeyByAlias('getEBulkApplications') })
    queryClient.invalidateQueries({ queryKey: apiClientHooks.getKeyByAlias('getGlobalStats') })
    navigate(`/`)
  }

  return <>
    <Helmet>
      <title>Payment</title>
    </Helmet>
    <Container component="main" maxWidth="xs">
      <Form method="post">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5" marginBottom={3}>
            Payment
          </Typography>
          <Typography component="h1" variant="h6" marginBottom={3} align="center">
            {appPricing?.price.label} - £{((appPricing?.amountToPayInPence ?? 0) / 100).toFixed(2)} to pay
            {(appPricing?.alreadyPaidInPence ?? 0) > 0 && <>
              <br />
              <em>(already paid: £{((appPricing?.alreadyPaidInPence ?? 0) / 100).toFixed(2)})</em>
            </>}
          </Typography>
          <Typography variant="body1" marginBottom={2}>
            Please choose a payment method to continue:
          </Typography>
          {appPricing?.allowedPaymentMethods
            .filter(method => method !== 'stripe') // Stripe is not allowed for staff (they use external stripe payment links)
            .map(method => (
              <Button
                key={method}
                variant='contained'
                color={selectedPaymentMethod === method ? 'success' : 'primary'}
                sx={{ marginBottom: 2 }}
                onClick={() => choosePaymentMethod(method)}
              >
                {paymentMethodTypeMapping[method].label}
              </Button>
            ))}
          {selectedPaymentMethod === 'none' && (
            <Alert severity="success" sx={{ marginTop: 2 }}>
              <AlertTitle>No Payment Necessary</AlertTitle>
              There is no payment necessary for this application.
              <br />
              <br />
              <Button variant='contained' onClick={onNoPaymentNecessary}>
                Continue without Payment
              </Button>
            </Alert>
          )}
          {selectedPaymentMethod === 'invoice' && (
            <Alert severity="success" sx={{ marginTop: 2 }}>
              <AlertTitle>Invoice Payment</AlertTitle>
              Please confirm that you wish for this application to be paid for by invoice.
              <br />
              <br />
              <Button variant='contained' onClick={onPayByInvoice}>
                Pay by Invoice
              </Button>
            </Alert>
          )}
          {selectedPaymentMethod === 'external_stripe_paymentlink' && (
            <Alert severity="success" sx={{ marginTop: 2 }}>
              <AlertTitle>External (Stripe - Payment Link)</AlertTitle>
              <ExternalPaymentForm
                application_id={application_id}
                price={appPricing?.price.priceInPence}
                payment_method='external_stripe_paymentlink'
                payment_reference_text={
                  <Typography component="p" variant="body2">
                    Once the customer has used a payment link to pay for this application, look up the transaction in Stripe and find the Payment Intent (<pre style={{ display: 'inline' }}>pi_ number</pre>) then use it as the payment reference.
                  </Typography>
                }
                buttonText='Paid Externally'
              />
            </Alert>
          )}
          {selectedPaymentMethod === 'external_creditcard' && (
            <Alert severity="success" sx={{ marginTop: 2 }}>
              <AlertTitle>External (Credit Card)</AlertTitle>
              <ExternalPaymentForm
                application_id={application_id}
                price={appPricing?.price.priceInPence}
                payment_method='external_creditcard'
                payment_reference_text={
                  <Typography component="p" variant="body2">
                    Once the customer has used a paid using a manual credit card system to pay for this application, specify a payment reference which relates to the transaction.
                  </Typography>
                }
                buttonText='Paid Externally'
              />
            </Alert>
          )}
          {selectedPaymentMethod === 'external_other' && (
            <Alert severity="success" sx={{ marginTop: 2 }}>
              <AlertTitle>External (Other)</AlertTitle>
              <ExternalPaymentForm
                application_id={application_id}
                price={appPricing?.price.priceInPence}
                payment_method='external_other'
                payment_reference_text={
                  <Typography component="p" variant="body2">
                    If payment has been made by any other external / manual method such as cash or cheque or bank transfer, use a payment reference that relates to the transaction.
                  </Typography>
                }
                buttonText='Paid Externally'
              />
            </Alert>
          )}
        </Box>
      </Form>
    </Container>
  </>
}

export default Element
