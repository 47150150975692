/**
 * RBApplicationReferenceType strings matching the following pattern: `[A-Za-z 0-9_\(\)\\\-/&apos;&quot;&amp;]{4,30}`
 * Base64 strings may contain the following characters: `A-Za-z0-9+/=`
 * 
 * Replace the following characters:
 * - `+` with `-`
 * - `/` with `_`
 * - `=` with `)`
 * 
 * @param base64 Base64 representation of the Application UUID
 * @returns A base64 string that is safe to use as a reference in the RBApplicationReferenceType
 */
export const makeBase64ReferenceSafe = (base64: string) => {
  return base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, ')');
}

/**
 * Reverse the transformation of `makeBase64ReferenceSafe`
 * @see makeBase64ReferenceSafe
 */
export const reverseBase64ReferenceSafe = (base64: string) => {
  return base64.replace(/-/g, '+').replace(/_/g, '/').replace(/\)/g, '=');
}

/**
 * Convert a UUID to a base64 string that is safe to use as a reference in the RBApplicationReferenceType
 * @param uuid Application ID
 */
export const uuidToBase64Safe = (uuid: string) => {
  try {
    const hex = uuid.replace(/-/g, '');
    let binaryString = '';
    for (let i = 0; i < hex.length; i += 2) {
      binaryString += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
    }
    const base64 = btoa(binaryString);
    return makeBase64ReferenceSafe(base64);
  } catch (e) {
    return '';
  }
}

/**
 * Convert a base64 string that is safe to use as a reference in the RBApplicationReferenceType to a UUID
 * @returns Application ID
 */
export const base64SafeToUuid = (base64safe: string) => {
  try {
    const base64 = reverseBase64ReferenceSafe(base64safe);
    const binaryString = atob(base64);
    let hex = '';
    for (let i = 0; i < binaryString.length; i++) {
      const hexChar = binaryString.charCodeAt(i).toString(16);
      hex += hexChar.padStart(2, '0');
    }
    return hex.replace(/(.{8})(.{4})(.{4})(.{4})(.{12})/, '$1-$2-$3-$4-$5');
  } catch (e) {
    return '';
  }
}

/**
 * Is a string a valid UUIDv4?
 */
export const isUuid = (maybe_uuid: string) => {
  return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/.test(maybe_uuid);
}
