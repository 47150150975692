import { Alert, AlertTitle, Box, Button, Modal, Typography, IconButton } from "@mui/material"
import { Close as CloseIcon } from '@mui/icons-material'
import { schemas } from "../generated/api/client"
import { z } from "zod"
import { apiClient, apiClientHooks } from "../bootstrapping/InitApiClient"
import { queryClient } from "../bootstrapping/InitReactQuery"
import { useState } from "react"
import IconButtonWithTooltip, { IconButtonWithTooltipProps } from "./IconButtonWithTooltip"
import SingleClickWaitingButton from "./SingleClickWaitingButton"

type Customer = z.infer<typeof schemas.Customer>

const ArchiveCustomerIconButton: React.FC<{ customer: Customer } & Omit<IconButtonWithTooltipProps, 'onclick'>> = ({ customer, ...iconButtonWithProps }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  return <>
    <IconButtonWithTooltip {...iconButtonWithProps} onClick={handleOpen} />
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
          Archive job
        </Typography>
        <Alert severity="error" sx={{ mb: 2 }}>
          <AlertTitle>Are you sure?</AlertTitle>
          If you archive this customer, this action cannot be reversed.
          <br />
          <br />
          <strong>Be sure you want to do this.</strong>
        </Alert>
        <SingleClickWaitingButton
          variant="contained"
          color="error"
          onClick={async () => {
            await apiClient.archiveCustomer(undefined, { params: { id: customer.id } })
            queryClient.invalidateQueries({ queryKey: apiClientHooks.getKeyByAlias('getCustomers') })
            setOpen(false)
          }}
          sx={{ mt: 2 }}
        >
          Archive Customer
        </SingleClickWaitingButton>
        <Button
          variant="text"
          color="primary"
          onClick={handleClose}
          sx={{ ml: 3, mt: 2 }}
        >
          Cancel
        </Button>
      </Box>
    </Modal>
  </>
}

export default ArchiveCustomerIconButton
