import React, { useCallback, useMemo } from 'react';
import {
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_Row,
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from 'material-react-table';
import {
  keepPreviousData,
  useQuery,
} from '@tanstack/react-query'
import { isAxiosError } from 'axios';
import { apiClient } from "../bootstrapping/InitApiClient";
import { z } from "zod";
import { schemas } from "../generated/api/client";
import { apiClientHooks } from "../bootstrapping/InitApiClient";
import { isSomething } from '../utils/utils';
import { useAppTable } from '../components/AppTable';
import { getTableFilterSelectOptions, labelsForEBulkApplicationsBatch_BatchMessageType, labelsForEBulkApplicationsBatch_ResultType } from '../constants/enums';
import AddIcon from '@mui/icons-material/Add';
import MinusIcon from '@mui/icons-material/Remove';
import { Stack, Typography } from '@mui/material';
import { JSONTree } from 'react-json-tree';
import { EBulkApplication } from '../routes/Invoicing';
import XMLViewer from 'react-xml-viewer';
import { base64SafeToUuid, uuidToBase64Safe } from '../utils/uuid';
import SingleClickWaitingButton from './SingleClickWaitingButton';

type EBulkApplicationsBatchesWithMeta = z.infer<typeof schemas.ReturnType_GetEBulkApplicationsBatches>
type EBulkApplicationsBatch = EBulkApplicationsBatchesWithMeta['rows'][number]

type ApplicationReceipt = {
  RBApplicationReference: string,
  ApplicationStatus: string,
  /** `RBApplicationReference` is an encoded version of the `application_id`. See: `uuid.ts` in the api */
  application_id: string,
}

export const BatchApplicationTable: React.FC<{ applicationIds: string[], batch: EBulkApplicationsBatch }> = ({ applicationIds, batch }) => {
  // Extract each ApplicationReceipt from the <eBulkApplicationReceipt /> XML (if the batch is of type CRB03)
  const applicationReceipts: { [key: string]: ApplicationReceipt } = useMemo(() => {
    if (batch.batch_message_type !== 'CRB03') return {}

    const parser = new DOMParser()
    const xmlDoc = parser.parseFromString(batch.batch_xml, 'text/xml')
    const receipts = xmlDoc.getElementsByTagName('eBulkApplicationReceipt')
    const result = Array.from(receipts).reduce((acc, receipt) => {
      const RBApplicationReference = receipt.getElementsByTagName('RBApplicationReference')[0].textContent;
      const ApplicationStatus = receipt.getElementsByTagName('ApplicationStatus')[0].textContent;
      if (RBApplicationReference === null || ApplicationStatus === null) return acc

      const application_id = base64SafeToUuid(RBApplicationReference)
      acc[application_id] = {
        RBApplicationReference,
        ApplicationStatus,
        application_id,
      };
      return acc;
    }, {} as { [key: string]: ApplicationReceipt });

    return result;
  }, [batch])

  // Combine the applicationIds with the keys from the applicationReceipts, and convert to a Set to remove duplicates
  const allApplicationIds = useMemo(() => {
    const result = new Set<string>(applicationIds)
    for (const key in applicationReceipts) {
      result.add(key)
    }
    return Array.from(result)
  }, [applicationIds, applicationReceipts])

  console.log("Applications.BatchApplicationTable: ", { applicationIds, batch, applicationReceipts, allApplicationIds })

  // Get all the applications
  const getEBulkApplications = apiClientHooks.useGetEBulkApplications({
    queries: {
      columnFilters: [
        { id: 'id', value: allApplicationIds, },
      ],
      page_index: '0',
      page_size: allApplicationIds.length.toString(),
    }
  })

  const columnHelper = createMRTColumnHelper<EBulkApplication>();

  const columns = [
    columnHelper.accessor(originalRow => originalRow.id, {
      header: 'Application ID',
      size: 40,
      enableColumnFilter: true,
    }),
    columnHelper.accessor(originalRow => originalRow.id, {
      header: 'EBulk Application ID',
      size: 40,
      enableColumnFilter: true,
      Cell: ({ cell }) => {
        const value = cell.getValue()
        return uuidToBase64Safe(value)
      },
    }),
    columnHelper.accessor('customer_name', {
      header: 'Customer Name',
      size: 40,
      enableColumnFilter: true,
    }),
    columnHelper.accessor('profile_given_name', {
      header: 'Given Name',
      size: 40,
      enableColumnFilter: true,
    }),
    columnHelper.accessor('profile_family_name', {
      header: 'Family Name',
      size: 40,
      enableColumnFilter: true,
    }),
    columnHelper.accessor(originalRow => originalRow.data.CurrentAddress?.Address.Postcode, {
      header: 'Postcode',
      size: 40,
      enableColumnFilter: true,
    }),
    columnHelper.accessor(originalRow => originalRow.data.DateOfBirth === undefined ? undefined : new Date(originalRow.data.DateOfBirth), {
      header: 'Date of Birth',
      size: 40,
      filterVariant: 'date-range',
      Cell: ({ cell }) => cell.getValue<Date | undefined>()?.toLocaleDateString()
    }),
    columnHelper.accessor(originalRow => originalRow.job_application_type === 'standard' ? 'true' : 'false', {
      header: 'STD',
      size: 40,
      filterVariant: 'checkbox',
      Cell: ({ cell }) => cell.getValue() === 'true' ? '✅' : '❌',
    }),
    columnHelper.accessor(originalRow => originalRow.job_application_type === 'enhanced' ? 'true' : 'false', {
      header: 'ENH',
      size: 40,
      filterVariant: 'checkbox',
      Cell: ({ cell }) => cell.getValue() === 'true' ? '✅' : '❌',
    }),
    columnHelper.accessor(originalRow => originalRow.documents_customer?.application?.withAdultFirst === true ? 'true' : 'false', {
      header: 'ISA',
      size: 40,
      filterVariant: 'checkbox',
      Cell: ({ cell }) => cell.getValue() === 'true' ? '✅' : '❌',
    }),
    columnHelper.accessor(originalRow => originalRow.job_volunteer === true ? 'true' : 'false', {
      header: 'Volunteer',
      size: 40,
      filterVariant: 'checkbox',
      Cell: ({ cell }) => cell.getValue() === 'true' ? '✅' : '❌',
    }),
    columnHelper.accessor('payment_pence', {
      header: 'Amount',
      size: 120,
      filterVariant: 'range-slider',
      muiFilterSliderProps: {
        valueLabelFormat: (value) => (value / 100).toLocaleString('en-GB', {
          style: 'currency',
          currency: 'GBP',
        }),
      },
      Cell: ({ cell }) => {
        const value = cell.getValue()
        if (typeof value !== 'number') return null
        return (value / 100).toLocaleString('en-GB', {
          style: 'currency',
          currency: 'GBP',
        })
      },
    }),
  ];

  const table = useMaterialReactTable({
    data: getEBulkApplications.data?.rows ?? [],
    columns,
    paginationDisplayMode: 'pages',
  });

  return <MaterialReactTable table={table} />;
}

const DetailPanel = ({ row }: { row: MRT_Row<EBulkApplicationsBatch> }) => {
  return <>
    <Typography variant="h6">Batch Actions</Typography>
    <SingleClickWaitingButton
      variant="contained"
      onClick={async () => {
        console.log("Process E-Bulk Batches for ", row.original.id)
        const result = await apiClient.validateEBulkApplicationsBatch({
          batch_id: row.original.id,
        })
        console.log("Applications.DetailPanel.ProcessBatches: ", { result }) 
      }}
    >
      Validate Batch {row.original.id}
    </SingleClickWaitingButton>
    <Typography variant="h6">Batch XML</Typography>
    <Typography variant="body2">
      <XMLViewer xml={row.original.batch_xml} collapsible />
    </Typography>
    <Typography variant="h6">Log Data</Typography>
    <Typography variant="body2">
      <JSONTree data={row.original.log_data} />
    </Typography>
    <Typography variant="h6">Applications</Typography>
    <Stack gap="0.5rem" minHeight="00px">
      <BatchApplicationTable applicationIds={row.original.application_ids} batch={row.original} />
    </Stack>
  </>;
};

export const Element: React.FC = () => {
  // Fetch the status from the querystring (if present)
  const q_ids = new URLSearchParams(window.location.search).getAll('id') ?? undefined
  const columnFilterInitialValues = useCallback((defaults: MRT_ColumnFiltersState) => [
    ...defaults,
    { id: 'id', value: q_ids },
  ], [q_ids])

  const {
    tableConfig,
  } = useAppTable<EBulkApplicationsBatch, EBulkApplicationsBatchesWithMeta, Error | null>({
    columnFilterInitialValues,
    useTableQuery: ({ columnFilters, globalFilter, pagination, sorting }) =>
      useQuery<EBulkApplicationsBatchesWithMeta>({
        queryKey: [
          ...apiClientHooks.getKeyByAlias('getEBulkApplicationsBatches'),
          columnFilters, //refetch when columnFilters changes
          globalFilter ?? '', //refetch when globalFilter changes
          pagination.pageIndex, //refetch when pagination.pageIndex changes
          pagination.pageSize, //refetch when pagination.pageSize changes
          sorting, //refetch when sorting changes
        ],
        queryFn: async () => {
          // Build the sort by string, and ensure it meets the API schema
          const sort = (() => {
            const firstSort = sorting[0]
            if (!firstSort) return undefined

            const compoundSort = `${firstSort.id} ${firstSort.desc ? 'desc' : 'asc'}`
            return schemas.Sort_GetEBulkApplicationsBatches.parse(compoundSort)
          })()

          try {
            const result = await apiClient.getEBulkApplicationsBatches({
              queries: {
                page_index: `${pagination.pageIndex}`,
                page_size: `${pagination.pageSize}`,
                sort,
                globalFilter: globalFilter.length > 0 ? globalFilter : undefined,
                columnFilters: schemas.ColumnFilters_GetEBulkApplicationsBatches.parse(
                  columnFilters
                    .map(filter => {
                      // Check ID is a valid column
                      const parseResult = schemas.ColumnFilters_GetEBulkApplicationsBatches.element.safeParse(filter)
                      if (!parseResult.success && parseResult.error.errors.find(e => e.path[0] === 'id')) {
                        console.error("Invalid column ID for filter: ", filter)
                        return undefined
                      }

                      // Transform Enum Filters

                      // Transform Boolean Filters
                      if (filter.id === 'active' || filter.id === 'allowPayByInvoice') {
                        const valueAsStringBoolean = z.union([z.literal('true'), z.literal('false')]).safeParse(filter.value)
                        if (!valueAsStringBoolean.success) {
                          console.error("Invalid Boolean Filter (value should be string 'true' or 'false'): ", filter)
                          return undefined
                        }

                        return {
                          id: filter.id,
                          value: valueAsStringBoolean.data === 'true' ? true : false,
                        }
                      }

                      // Transform Date Range Filters
                      if (filter.id === 'createdAt' || filter.id === 'updatedAt') {
                        const values = z.array(z.date().optional()).safeParse(filter.value)
                        if (!values.success || values.data.length !== 2) {
                          console.error("Invalid date range filter: ", filter)
                          return undefined
                        }
                        if (values.data[0] === undefined && values.data[1] === undefined) {
                          // Nothing to filter on!
                          return undefined
                        }

                        return {
                          id: filter.id,
                          from: values.data[0]?.toISOString(),
                          to: values.data[1]?.toISOString(),
                        }
                      }

                      // Everything else is just a text filter
                      var parsedValue = z.string().safeParse(filter.value)
                      if (!parsedValue.success) {
                        console.error("Invalid text filter: ", filter)
                        return undefined
                      }

                      return {
                        id: filter.id,
                        value: parsedValue.data,
                      }
                    })
                    .filter(isSomething)
                )
              },
            })

            console.log("Applications.Element.queryFn: ", { result })
            return result
          } catch (error) {
            if (!isAxiosError(error)) {
              console.error("Error fetching jobs (non-Axios): ", error)
              throw error
            }

            const parsedValue = schemas.ErrorResponse.safeParse(error.response?.data)
            if (!parsedValue.success) {
              console.error("Error fetching jobs (unknown error): ", error)
              throw error
            }

            console.error("Error fetching jobs: ", parsedValue.data.error)
            return Promise.reject(parsedValue.data.error)
          }
        },
        placeholderData: keepPreviousData, //don't go to 0 rows when refetching or paginating to next page
      }),
    columns: useMemo<MRT_ColumnDef<EBulkApplicationsBatch>[]>(() => [
      {
        accessorKey: 'batch_number',
        header: 'Batch Number',
        size: 50,
        enableSorting: true,
        enableColumnFilter: true,
        filterVariant: 'range-slider',
      },
      {
        accessorKey: 'batch_message_type',
        header: 'Batch Message Type',
        size: 50,
        enableSorting: true,
        enableColumnFilter: true,
        Cell: ({ cell }) => labelsForEBulkApplicationsBatch_BatchMessageType[cell.getValue() as EBulkApplicationsBatch['batch_message_type']],
        filterVariant: 'select',
        filterSelectOptions: getTableFilterSelectOptions(labelsForEBulkApplicationsBatch_BatchMessageType),
      },
      {
        accessorKey: 'batch_filename',
        header: 'Batch Filename',
        size: 150,
        enableSorting: true,
        enableColumnFilter: true,
        Cell: ({ cell }) => {
          // The filenames are long, so just get the last segment of the path (everything after the last /)
          const value = cell.getValue() as EBulkApplicationsBatch['batch_filename']
          const parts = value.split('/')
          return parts[parts.length - 1]
        },
      },
      {
        accessorKey: 'application_ids',
        header: 'Application IDs',
        size: 150,
        enableSorting: true,
        enableColumnFilter: true,
        Cell: ({ cell }) => {
          const value = cell.getValue() as EBulkApplicationsBatch['application_ids']
          return `${value.length} application${value.length === 1 ? '' : 's'}`
        },
      },
      {
        accessorKey: 'transferred_at',
        header: 'Transferred At',
        size: 150,
        enableSorting: true,
        enableColumnFilter: true,
        Cell: ({ cell }) => {
          const value = cell.getValue() as EBulkApplicationsBatch['transferred_at']
          return value ? new Date(value).toLocaleString('en-GB', { day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit' }) : ''
        },
        filterVariant: 'datetime-range',
        muiFilterDateTimePickerProps: {
          format: 'dd-MMM-yyyy HH:mm',
        },
      },
      {
        accessorKey: 'result_type',
        header: 'Batch Message Type',
        size: 150,
        enableSorting: true,
        enableColumnFilter: true,
        Cell: ({ cell }) => {
          const value = cell.getValue() as EBulkApplicationsBatch['result_type']
          if (value === undefined) return ''
          return labelsForEBulkApplicationsBatch_ResultType[value]
        },
        filterVariant: 'select',
        filterSelectOptions: getTableFilterSelectOptions(labelsForEBulkApplicationsBatch_ResultType),
      },
    ], []),
  })

  const table = useMaterialReactTable({
    ...tableConfig,
    muiExpandButtonProps: ({ row }) => ({
      children: row.getIsExpanded() ? <MinusIcon /> : <AddIcon />,
    }),
    renderDetailPanel: props => <DetailPanel {...props} />,
  });

  return <MaterialReactTable table={table} />;
}

export default Element;
