import { Alert, AlertTitle, Box, Button, Modal, Typography, IconButton } from "@mui/material"
import { Close as CloseIcon } from '@mui/icons-material'
import { schemas } from "../generated/api/client"
import { z } from "zod"
import { apiClient, apiClientHooks } from "../bootstrapping/InitApiClient"
import { queryClient } from "../bootstrapping/InitReactQuery"
import { useState } from "react"
import IconButtonWithTooltip, { IconButtonWithTooltipProps } from "./IconButtonWithTooltip"
import SingleClickWaitingButton from "./SingleClickWaitingButton"
import { labelsForEBulkApplicationStatus } from "../constants/enums"

type Job = z.infer<typeof schemas.Job>
type ApplicationStatus = z.infer<typeof schemas.EBulkApplication>['status']

const ArchiveJobIconButton: React.FC<{ job: Job } & Omit<IconButtonWithTooltipProps, 'onclick'>> = ({ job, ...iconButtonWithProps }) => {
  // Get the applications for this job
  const getEBulkApplications = apiClientHooks.useGetEBulkApplications({
    queries: {
      columnFilters: [
        { id: 'job_id', value: job.id },
      ],
    }
  })
  const applications = getEBulkApplications.data?.rows || []
  const applicationsByStatus = applications.reduce((acc, application) => {
    acc[application.status] = (acc[application.status] || 0) + 1
    return acc
  }, {} as Record<ApplicationStatus, number>)

  // Get the invites for this job
  const getInvites = apiClientHooks.useGetInvites({
    queries: {
      columnFilters: [
        { id: 'active', value: true },
        { id: 'job_id', value: job.id },
      ],
    }
  })
  const invites = getInvites.data?.rows || []

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  return <>
    <IconButtonWithTooltip {...iconButtonWithProps} onClick={handleOpen} />
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
          Archive job
        </Typography>
        <Alert severity="error" sx={{ mb: 2 }}>
          <AlertTitle>Are you sure?</AlertTitle>
          If you archive this job, this action cannot be reversed.
          <ul>
            <li>
              This job has <strong>{applications.length}</strong> application{applications.length === 1 ? '' : 's'}.
              {applications.length > 0 && <>
                <ul>
                  {Object.entries(applicationsByStatus).map(([status, count]) => (
                    <li key={status}><strong>{count}</strong> in <em>{labelsForEBulkApplicationStatus[status as ApplicationStatus]}</em></li>
                  ))}
                </ul>
              </>}
            </li>
            <li>This job has <strong>{invites.length}</strong> active applicant invites.</li>
          </ul>
          <strong>Be sure you want to do this.</strong>
        </Alert>
        <SingleClickWaitingButton
          variant="contained"
          color="error"
          onClick={async () => {
            await apiClient.archiveJob(undefined, { params: { id: job.id } })
            queryClient.invalidateQueries({ queryKey: apiClientHooks.getKeyByAlias('getJobs') })
            setOpen(false)
          }}
          sx={{ mt: 2 }}
        >
          Archive Job
        </SingleClickWaitingButton>
        <Button
          variant="text"
          color="primary"
          onClick={handleClose}
          sx={{ ml: 3, mt: 2 }}
        >
          Cancel
        </Button>
      </Box>
    </Modal>
  </>
}

export default ArchiveJobIconButton
