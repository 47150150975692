import { Alert, AlertTitle, Box, Button, Modal, Typography, IconButton } from "@mui/material"
import { Close as CloseIcon } from '@mui/icons-material'
import { schemas } from "../generated/api/client"
import { z } from "zod"
import { apiClient, apiClientHooks } from "../bootstrapping/InitApiClient"
import { queryClient } from "../bootstrapping/InitReactQuery"
import { useState } from "react"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { TextFieldElement } from "react-hook-form-mui"
import IconButtonWithTooltip, { IconButtonWithTooltipProps } from "./IconButtonWithTooltip"

type EBulkApplication = z.infer<typeof schemas.EBulkApplication>

const ResendApplicationIconButton: React.FC<{ dbsApp: EBulkApplication } & Omit<IconButtonWithTooltipProps, 'onclick'>> = ({ dbsApp, ...iconButtonWithProps }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const rejectableStatuses: Array<typeof dbsApp.status> = [
    'error_from_DBS',
  ]
  return rejectableStatuses.includes(dbsApp.status)
    ? <>
      <IconButtonWithTooltip {...iconButtonWithProps} onClick={handleOpen} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
            Resend Application to the DBS
          </Typography>
          <Alert severity="info" sx={{ mb: 2 }}>
            <AlertTitle>Are you sure?</AlertTitle>
            This will return to the <em>Awaiting Submission to DBS</em> status.
          </Alert>
          <Button
            variant="contained"
            color="primary"
            onClick={async () => {
              await apiClient.resendMyEBulkApplicationByID(undefined, { params: { id: dbsApp.id } })
              queryClient.invalidateQueries({ queryKey: apiClientHooks.getKeyByAlias('getEBulkApplications') })
              setOpen(false)
            }}
            sx={{ mt: 2 }}
          >
            Resend Application
          </Button>
          <Button
            variant="text"
            color="primary"
            onClick={handleClose}
            sx={{ ml: 3, mt: 2 }}
          >
            Cancel
          </Button>
        </Box>
      </Modal>
    </>
    : null
}

export default ResendApplicationIconButton
