import { z } from "zod";
import { LocalFormSchemaSuperRefineFn } from "./applicationUpdateSchema";

export const rulesValidDriverLicenceNumber: LocalFormSchemaSuperRefineFn = async (value, context) => {
  if (value.ApplicantIdentityDetails?.DriverLicenceDetails?.DriverLicenceNumber == null) return
  if (value.ApplicantIdentityDetails?.DriverLicenceDetails?.DriverLicenceIssueCountry !== 'GB') return

  const driverLicenceNumber = value.ApplicantIdentityDetails.DriverLicenceDetails.DriverLicenceNumber
  const result = isValidDriverLicenceNumber(driverLicenceNumber, value)
  console.log("Validating Driver Licence Number", result)
  if (result !== 'yes') {
    context.addIssue({
      code: z.ZodIssueCode.custom,
      path: ['ApplicantIdentityDetails', 'DriverLicenceDetails', 'DriverLicenceNumber'],
      message: 'Invalid Driver Licence Number.',
    })
  }
}

export const computeDriverLicenceNumberPrefix = (value: Parameters<LocalFormSchemaSuperRefineFn>[0], params: { convertMacToMc: boolean } = { convertMacToMc: false }): string => {
  const prefix: string[] = []

  const forename = (value.Forename ?? '').toUpperCase()
  const numOfMiddlenames = value.Middlenames?.Middlename.length ?? 0
  const firstMiddlename = (value.Middlenames?.Middlename[0] ?? '').toUpperCase()
  const surname = (value.PresentSurname ?? '').toUpperCase()
  const dob = (value.DateOfBirth ?? '0000-00-00').match(/^\d{4}-\d{2}-\d{2}$/)
    ? value.DateOfBirth ?? '0000-00-00'
    : '0000-00-00'
  const isFemale = value.Gender === 'female'

  // The first five characters must match the first five characters of the applicant’s current surname as provided in CurrentFullName.
  // If the applicant’s current surname is less than five characters long, then the whole surname must match the beginning of the licence number 
  // and the remainder of the first five characters of the licence number must be the digit ‘9’.
  // Note that, in the driver licence number, apostrophes can be omitted from the first five characters of the surname and ‘Mac’ can be shortened to ‘Mc’ in the first three characters of the surname.

  const surnameWithoutApostrophes = surname.replace(/'/g, '')
  const surnameWithoutMac = surnameWithoutApostrophes.replace(/^MAC/, 'MC')
  const surnamePrefix = params.convertMacToMc
    ? surnameWithoutMac.substring(0, 5)
    : surnameWithoutApostrophes.substring(0, 5)
  const surnamePadded = surnamePrefix.padEnd(5, '9')
  prefix.splice(0, surnamePadded.length, ...surnamePadded.split(''))

  // The 6th and 11th characters, when concatenated in this order, must match the rightmost two digits of the year component of the applicant’s date of birth, as provided in DateOfBirth.
  prefix[5] = dob.substring(2, 3) // decade of year
  prefix[10] = dob.substring(3, 4) // year of year

  // The 7th and 8th characters together convey both the month component of the date of birth and the applicant’s gender, with 5 being added to the 7th character to indicate that the licence holder is female. Therefore:
  // o If the 7th character is a number less than 5, the 7th and 8th characters, in this order, must match the month component of the applicant’s date of birth, as provided in DateOfBirth, and the applicant’s gender, as provided in Gender, must be ‘male’. (E.g. a value of ‘06’ for the 7th and 8th characters must match with a gender of ‘male’ and a value of ‘06’ for the month component of the date of birth.)
  // o If the 7th character is a number greater than or equal to 5, the 7th character with 5 subtracted from it combined with the 8th character, in this order, must match the month component of the applicant’s date of birth, as provided in DateOfBirth, and the applicant’s gender, as provided in Gender, must be ‘female’. (E.g. a value of ‘56’ for the 7th and 8th characters must match with a gender of ‘female’ and a value of ‘06’ for the month component of the date of birth.)
  // o Note also that this validation must fail if the 7th character is not numeric.
  prefix[6] = dob.substring(5, 6) === '0'
    ? isFemale
      ? '5'
      : '0'
    : isFemale
      ? '6'
      : '1'
  prefix[7] = dob.substring(6, 7)

  // o The 9th and 10th characters, in this order, must match the day component of the applicant’s date of birth, as provided in DateOfBirth.
  prefix[8] = dob.substring(8, 9)
  prefix[9] = dob.substring(9, 10)

  // o The 12th character must match the first letter of the applicant’s current forename (i.e. their first name), as provided in CurrentFullName.
  prefix[11] = forename.charAt(0) ?? 'x'

  // o If the applicant has at least one current middle name, as provided in CurrentFullName, the 13th character must match the first letter of the first middle name provided within CurrentFullName. If the applicant’s CurrentFullName has no middle names, the 13th character must be the digit ‘9’.
  prefix[12] = numOfMiddlenames === 0
    ? '9'
    : firstMiddlename.charAt(0) ?? 'x'

/*
  // o No validation is applied to the 14th and subsequent characters.
*/
  return prefix.join('')
}

export const isValidDriverLicenceNumber = (driverLicenceNumber: string, value: Parameters<LocalFormSchemaSuperRefineFn>[0]): 'yes' | 'no-too-long' | 'no-invalid-prefix' => {
  // We don't validate driver licence numbers that are less than 16 characters
  if (driverLicenceNumber.length < 16) return 'yes'
  // The can't be more than 18 characters
  if (driverLicenceNumber.length > 18) return 'no-too-long'

  // Upper case the driver licence number
  const upperDriverLicenceNumber = driverLicenceNumber.toUpperCase()

  // Check the first 13 characters of the driver licence number match
  const enteredPrefix = upperDriverLicenceNumber.substring(0, 13)
  const computedPrefix = computeDriverLicenceNumberPrefix(value)
  if (enteredPrefix === computedPrefix) return 'yes'
  console.log('isValidDriverLicenceNumber invalid-straight', { enteredPrefix, computedPrefix })

  // If my entered prefix starts MAC, then I should also check for MC
  const computedPrefixMc = computeDriverLicenceNumberPrefix(value, { convertMacToMc: true })
  if (enteredPrefix === computedPrefixMc) return 'yes'
  console.log('isValidDriverLicenceNumber invalid-mac2mc', { enteredPrefix, computedPrefix })

  return 'no-invalid-prefix'
}
