import React from "react";
import { Alert, AlertTitle, Box, Button, Container, Grid, Typography } from "@mui/material";
import { Form, Link, useNavigate, useParams } from "react-router-dom";
import { apiClientHooks } from "../bootstrapping/InitApiClient";
import { Helmet } from "react-helmet-async";
import { isSomething } from "../utils/utils";
import { useProfileAssured } from "../bootstrapping/InitRequireProfile";
import { queryClient } from "../bootstrapping/InitReactQuery";
import { getIdentityDocumentsForMeetingData } from "../common/ApplicationMeetingData";
import { schemas } from "../generated/api/client";
import { z } from "zod";
import RejectApplicationButton from "../components/RejectIApplicationButton";
import ChangeJobRoleForApplicationButton from "../components/ChangeJobRoleForApplicationButton";
import ApplicationNeedsChangesButton from "../components/ApplicationNeedsChangesButton";

type ApplicationDocumentsGridProps = {
  documents?: Parameters<typeof getIdentityDocumentsForMeetingData>[0];
  showAdultFirst?: boolean;
}
const ApplicationDocumentsGrid: React.FC<ApplicationDocumentsGridProps> = ({ documents, showAdultFirst }) => {
  if (!documents) return <></>

  const identityDocuments = getIdentityDocumentsForMeetingData(documents)
  const group1 = identityDocuments.filter(each => each.groupKey === 'group1')
  const group2a = identityDocuments.filter(each => each.groupKey === 'group2a')
  const group2b = identityDocuments.filter(each => each.groupKey.startsWith('group2b'))

  const docToOutput = (each: ReturnType<typeof getIdentityDocumentsForMeetingData>[number]) => {
    const qualifier = each.groupKey.endsWith('3mo')
      ? <> <em>(within the last 3 months)</em></>
      : each.groupKey.endsWith('12mo')
        ? <> <em>(within the last year)</em></>
        : each.groupKey.endsWith('anyTime')
          ? <> <em>(must be valid)</em></>
          : ''
    return (
      <Typography
        key={`${each.groupKey}.${each.docKey}`}
        marginBottom={1}
      >
        {each.doc.title}{qualifier}
      </Typography>
    )
  }

  return (
    <Grid container spacing={2} marginBottom={2}>
      <Grid item xs={6}>
        <Typography fontWeight="bold">Group 1 Documents</Typography>
      </Grid>
      <Grid item xs={6}>
        {group1.length > 0
          ? group1.map(docToOutput)
          : <>❌</>}
      </Grid>
      <Grid item xs={6}>
        <Typography fontWeight="bold">Group 2a Documents</Typography>
      </Grid>
      <Grid item xs={6}>
        {group2a.length > 0
          ? group2a.map(docToOutput)
          : <>❌</>}
      </Grid>
      <Grid item xs={6}>
        <Typography fontWeight="bold">Group 2b Documents</Typography>
      </Grid>
      <Grid item xs={6}>
        {group2b.length > 0
          ? group2b.map(docToOutput)
          : <>❌</>}
      </Grid>
      <Grid item xs={6}>
        <Typography fontWeight="bold">Confirmations</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography><strong>{documents.confirmations?.currentAddressCount}</strong> Current Address</Typography>
        <Typography><strong>{documents.confirmations?.dateOfBirthCount}</strong> Date of Birth</Typography>
        <Typography><strong>{documents.confirmations?.nameAndNameChangesCount}</strong> Current Name and Name Changes</Typography>
      </Grid>
      {showAdultFirst === true
        ? <>
          <Grid item xs={6}>
            <Typography fontWeight="bold">With Adult First</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>{documents.application?.withAdultFirst ? '✅' : '❌'}</Typography>
          </Grid>
        </>
        : null}
    </Grid>
  )
}

type ApplicationPaymentGridProps = {
  application?: z.infer<typeof schemas.EBulkApplication>;
}
const ApplicationPaymentGrid: React.FC<ApplicationPaymentGridProps> = ({ application }) => {
  const priceForEBulkApplicationByID = apiClientHooks.usePriceForEBulkApplicationByID({
    params: {
      id: application?.id ?? 'disabled-without-id',
    },
  }, {
    enabled: !!application?.id,
  })
  const pricing = priceForEBulkApplicationByID.data

  if (!application) return <></>

  return (
    <Grid container spacing={2}>
      {/* Application Fields */}
      <Grid item xs={6}>
        <Typography fontWeight="bold">App Payment Method</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>{application?.payment_method}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography fontWeight="bold">App Payment Code</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>{application?.payment_code}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography fontWeight="bold">App Payment Amount</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>
          {
            application?.payment_pence == null
              ? null
              : <>£{((application?.payment_pence ?? 0) / 100).toFixed(2)}</>
          }
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography fontWeight="bold">App Payment Reference</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>{application?.payment_reference}</Typography>
      </Grid>
      {/* PAYMENTS */}
      <Grid item xs={6}>
        <Typography fontWeight="bold">Already Paid</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>
          {
            pricing?.alreadyPaidInPence == null
              ? null
              : <>£{((pricing?.alreadyPaidInPence ?? 0) / 100).toFixed(2)}</>
          }
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography fontWeight="bold">Currently Unpaid</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>
          {
            pricing?.amountToPayInPence == null
              ? null
              : <>£{((pricing?.amountToPayInPence ?? 0) / 100).toFixed(2)}</>
          }
        </Typography>
      </Grid>
    </Grid>
  )
}

export const Element: React.FC = () => {
  const navigate = useNavigate()

  const { application_id } = useParams<{ application_id?: string }>()
  if (!application_id) throw new Error('application_id is required')

  const profile = useProfileAssured()
  if (profile.countersignatory_number == null) {
    throw new Error('You are not a countersignatory. You should not be here.')
  }

  // Get all the details related to the application
  // - The application itself
  // - The job the application is for
  // - The customer the job is for
  const getEBulkApplicationByID = apiClientHooks.useGetEBulkApplicationByID({ params: { id: application_id } })
  const application = getEBulkApplicationByID.data

  const getJobs = apiClientHooks.useGetJobs({
    queries: {
      columnFilters: [
        { id: 'id', value: [ application?.job_id ?? 'disabled-without-id' ] },
      ]
    }
  }, {
    enabled: !!application?.job_id,
  })
  const job = getJobs.data?.rows.find(j => j.id === application?.job_id)

  const getJobCountersignedApplicationCount = apiClientHooks.useGetJobApplicationsCountersignedCount({
    params: {
      id: job?.id ?? 'disabled-without-id',
    },
  }, {
    enabled: !!job?.id,
  })
  const jobCountersignedApplicationCount = getJobCountersignedApplicationCount.data?.countersignedApplicationsCount

  // Load the Customer to determine if payment by invoice is allowed
  const getCustomers = apiClientHooks.useGetCustomers({
    queries: {
      columnFilters: [
        { id: 'id', value: [ job?.customer_id ?? 'disabled-without-id' ] },
      ]
    }
  }, {
    enabled: !!job?.customer_id,
  })
  const customer = getCustomers.data?.rows.find(c => c.id === job?.customer_id)

  const countersignEBulkApplicationByID = apiClientHooks.useCountersignEBulkApplicationByID({
    params: {
      id: application_id,
    },
  })

  const doCountersigning = async () => {
    await countersignEBulkApplicationByID.mutateAsync(undefined, {})
    queryClient.invalidateQueries({ queryKey: apiClientHooks.getKeyByAlias('getGlobalStats') }) // Don't wait for this to finish
    navigate('/')
  }

  return <>
    <Helmet>
      <title>Countersigning</title>
    </Helmet>
    <Container component="main" maxWidth="xs">
      <Form method="post">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h4" marginBottom={3}>
            Countersigning
          </Typography>
          {application?.restart_of_application_id != null && <>
            <Alert severity="warning" sx={{ marginBottom: 2 }}>
              <AlertTitle>Restarted Application</AlertTitle>
              This application was previously rejected.
            </Alert>
          </>}
          <Typography component="h1" variant="h5" marginBottom={3}>
            About the Customer
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography fontWeight="bold">Customer Name</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>
                {customer?.name} <Link to={`/customers?search=${customer?.id}&expand=${customer?.id}`} style={{ textDecoration: 'none' }}>🔗</Link>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography fontWeight="bold">Healthcare Sector</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{customer?.sector_healthcare ? '✅' : '❌'}</Typography>
            </Grid>
          </Grid>
          <Typography component="h1" variant="h5" marginTop={3} marginBottom={3}>
            About the Job Role
          </Typography>
          {jobCountersignedApplicationCount === 0 && (
            <Alert severity="warning" sx={{ marginBottom: 2 }}>
              <AlertTitle>First Application for Job Role</AlertTitle>
              <Typography variant='body2'>
                There are no previously countersigned applications for this job role.
              </Typography>
              <Typography variant='body2' marginTop={2}>
                <em>Please ensure the job role settings are correct.</em>
              </Typography>
            </Alert>
          )}
          {jobCountersignedApplicationCount != null && jobCountersignedApplicationCount > 0 && (
            <Alert severity="success" sx={{ marginBottom: 2 }}>
              <AlertTitle>Existing Applications for Job Role</AlertTitle>
              <Typography variant='body2'>
                This job role has been countersigned <strong>{jobCountersignedApplicationCount}</strong> times.
              </Typography>
            </Alert>
          )}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography fontWeight="bold">Role</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>
                {job?.role} <Link to={`/jobs?search=${job?.id}&expand=${job?.id}`} style={{ textDecoration: 'none' }}>🔗</Link>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography fontWeight="bold">With Adult First</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{job?.with_adult_first ? '✅' : '❌'}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography fontWeight="bold">Application Type</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{job?.application_type}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography fontWeight="bold">Application Workforce</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{job?.application_workforce}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography fontWeight="bold">Working with Adults</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{job?.working_with_adults ? '✅' : '❌'}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography fontWeight="bold">Working with Children</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{job?.working_with_children ? '✅' : '❌'}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography fontWeight="bold">Working at Home Address</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{job?.working_at_home_address ? '✅' : '❌'}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography fontWeight="bold">Volunteer</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{job?.volunteer ? '✅' : '❌'}</Typography>
            </Grid>
          </Grid>
          <Alert severity="info" sx={{ marginTop: 2 }}>
            <AlertTitle>Issue with Job Role?</AlertTitle>
            {application != null && (
              <ChangeJobRoleForApplicationButton
                variant='contained'
                color='success'
                sx={{ marginTop: 2 }}
                dbsApp={application}
              >
                🧑‍⚕️ Change Job Role
              </ChangeJobRoleForApplicationButton>
            )}
            {application != null && <>
              <ApplicationNeedsChangesButton
                variant='contained'
                color='warning'
                sx={{ marginTop: 2 }}
                dbsApp={application}
              >
                ⚠️ Changes Needed
              </ApplicationNeedsChangesButton>
              <RejectApplicationButton
                variant='contained'
                color='error'
                sx={{ marginTop: 2 }}
                dbsApp={application}
              >
                💀 Reject Application
              </RejectApplicationButton>
            </>}
          </Alert>
          <Typography component="h1" variant="h5" marginTop={3} marginBottom={2}>
            About the Applicant
          </Typography>
          {application?.test_application && (
            <Alert severity="error" sx={{ marginBottom: 2 }}>
              <AlertTitle>Test Application</AlertTitle>
              <Typography variant='body2'>
                This is a <strong>test application</strong> and will <strong>not</strong> be submitted to the DBS. Instead it will go straight to complete.
              </Typography>
            </Alert>
          )}
          <Typography component="h1" variant="h6" marginTop={1} marginBottom={2}>
            Identity
          </Typography>
          <Grid container spacing={2} marginBottom={2}>
            <Grid item xs={6}>
              <Typography fontWeight="bold">Gender</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{application?.data.Gender}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography fontWeight="bold">Contact Number</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{application?.data.AdditionalApplicantDetails?.ContactNumber ? '✅' : '❌'}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography fontWeight="bold">NI Number</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{application?.data.NiNumber}</Typography>
            </Grid>
          </Grid>
          <Typography component="h1" variant="h6" marginTop={1} marginBottom={2}>
            Names
          </Typography>
          <Grid container spacing={2} marginBottom={2}>
            <Grid item xs={6}>
              <Typography fontWeight="bold">Current Name</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>
                {application?.data.Title} {application?.data.Forename} {application?.data.Middlenames?.Middlename.join(' ')} {application?.data.PresentSurname}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography fontWeight="bold">Name at Birth</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>
                {application?.data.AdditionalApplicantDetails?.BirthSurname
                  ? <>
                    {application?.data.AdditionalApplicantDetails?.BirthSurname}
                    {application?.data.AdditionalApplicantDetails?.BirthSurnameUntil ? ' until ' + application?.data.AdditionalApplicantDetails?.BirthSurnameUntil : ''}
                  </>
                  : '❌'}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography fontWeight="bold">Other Forenames</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>
                {(application?.data.AdditionalApplicantDetails?.OtherForenames?.OtherForename.length ?? 0) > 0
                  ? application?.data.AdditionalApplicantDetails?.OtherForenames?.OtherForename.join(', ')
                  : '❌'}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography fontWeight="bold">Other Surnames</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>
                {(application?.data.AdditionalApplicantDetails?.OtherSurnames?.OtherSurname.length ?? 0) > 0
                  ? application?.data.AdditionalApplicantDetails?.OtherSurnames?.OtherSurname.join(', ')
                  : '❌'}
              </Typography>
            </Grid>
          </Grid>
          <Typography component="h1" variant="h6" marginTop={1} marginBottom={2}>
            Birth
          </Typography>
          <Grid container spacing={2} marginBottom={2}>
            <Grid item xs={6}>
              <Typography fontWeight="bold">Date of Birth</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{application?.data.DateOfBirth}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography fontWeight="bold">Birth Town</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{application?.data.AdditionalApplicantDetails?.BirthTown}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography fontWeight="bold">Birth County</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{application?.data.AdditionalApplicantDetails?.BirthCounty}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography fontWeight="bold">Birth Country</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{application?.data.AdditionalApplicantDetails?.BirthCountry}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography fontWeight="bold">Birth Nationality</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{application?.data.AdditionalApplicantDetails?.BirthNationality}</Typography>
            </Grid>
          </Grid>
          <Typography component="h1" variant="h6" marginTop={1} marginBottom={2}>
            Addresses
          </Typography>
          {[
            application?.data.CurrentAddress,
            ...(application?.data.PreviousAddress ?? []),
          ]
            .filter(isSomething)
            .map((address, index) => (
              <Grid container spacing={2} key={index}>
                <Grid item xs={6}>
                  <Typography fontWeight="bold">
                    {!!address.ResidentFromGyearMonth
                      ? <>Since: {address.ResidentFromGyearMonth}</>
                      : !!(address.ResidentDates as any)?.ResidentFromGyearMonth && !!(address.ResidentDates as any)?.ResidentToGyearMonth
                        ? <>{(address.ResidentDates as any).ResidentFromGyearMonth} to {(address.ResidentDates as any).ResidentToGyearMonth}</>
                        : <>⚠️</>
                    }
                  </Typography>
                </Grid>
                <Grid item xs={6} marginBottom={2}>
                  <Typography>
                    {address.Address.AddressLine1 ? <>{address.Address.AddressLine1},<br /></> : ''}
                    {address.Address.AddressLine2 ? <>{address.Address.AddressLine2},<br /></> : ''}
                    {address.Address.AddressTown ? <>{address.Address.AddressTown},<br /></> : ''}
                    {address.Address.AddressCounty ? <>{address.Address.AddressCounty},<br /></> : ''}
                    {address.Address.Postcode ? <>{address.Address.Postcode},<br /></> : ''}
                    {address.Address.CountryCode ? <>{address.Address.CountryCode}<br /></> : ''}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          <Typography component="h1" variant="h6" marginTop={1} marginBottom={2}>
            Passport
          </Typography>
          <Grid container spacing={2} marginBottom={2}>
            <Grid item xs={6}>
              <Typography fontWeight="bold">Number</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{application?.data.ApplicantIdentityDetails?.PassportDetails?.PassportNumber ?? '❌'}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography fontWeight="bold">Issue Date</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{application?.data.ApplicantIdentityDetails?.PassportDetails?.PassportIssueDate ?? '❌'}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography fontWeight="bold">Date of Birth</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{application?.data.ApplicantIdentityDetails?.PassportDetails?.PassportDob ?? '❌'}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography fontWeight="bold">Nationality</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{application?.data.ApplicantIdentityDetails?.PassportDetails?.PassportNationality ?? '❌'}</Typography>
            </Grid>
          </Grid>
          <Typography component="h1" variant="h6" marginTop={1} marginBottom={2}>
            Driver Licence
          </Typography>
          <Grid container spacing={2} marginBottom={2}>
            <Grid item xs={6}>
              <Typography fontWeight="bold">Number</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{application?.data.ApplicantIdentityDetails?.DriverLicenceDetails?.DriverLicenceNumber ?? '❌'}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography fontWeight="bold">Date of Birth</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{application?.data.ApplicantIdentityDetails?.DriverLicenceDetails?.DriverLicenceDOB ?? '❌'}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography fontWeight="bold">Type</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{application?.data.ApplicantIdentityDetails?.DriverLicenceDetails?.DriverLicenceType ?? '❌'}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography fontWeight="bold">Valid From</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{application?.data.ApplicantIdentityDetails?.DriverLicenceDetails?.DriverLicenceValidFrom ?? '❌'}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography fontWeight="bold">Issuing Country</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{application?.data.ApplicantIdentityDetails?.DriverLicenceDetails?.DriverLicenceIssueCountry ?? '❌'}</Typography>
            </Grid>
          </Grid>
          <Typography component="h1" variant="h5" marginTop={3} marginBottom={2}>
            Intended Applicant Documents
          </Typography>
          <ApplicationDocumentsGrid documents={application?.documents_applicant} />
          <Typography component="h1" variant="h5" marginTop={3} marginBottom={2}>
            Validated Applicant Documents
          </Typography>
          <ApplicationDocumentsGrid documents={application?.documents_customer} showAdultFirst />
          <Typography component="h1" variant="h5" marginTop={3} marginBottom={2}>
            Pricing & Payments
          </Typography>
          <ApplicationPaymentGrid application={application} />
          {application?.test_application && (
            <Alert severity="error" sx={{ marginTop: 2 }}>
              <AlertTitle>Test Application</AlertTitle>
              <Typography variant='body2'>
                This is a <strong>test application</strong> and will <strong>not</strong> be submitted to the DBS. Instead it will go straight to complete.
              </Typography>
            </Alert>
          )}
          <Alert severity="success" sx={{ marginTop: 2 }}>
            <AlertTitle>Countersigning</AlertTitle>
            <Typography variant='body1' marginBottom={2}>
              Please confirm that you are <strong>{profile.given_name} {profile.family_name}</strong> and wish to <strong>countersign</strong> this application.
            </Typography>
            <Button variant='contained' onClick={doCountersigning}>
              Countersign Application
            </Button>
            {application != null && <>
              <ApplicationNeedsChangesButton
                variant='contained'
                color='warning'
                sx={{ marginTop: 2 }}
                dbsApp={application}
              >
                ⚠️ Changes Needed
              </ApplicationNeedsChangesButton>
              <RejectApplicationButton
                variant='contained'
                color='error'
                sx={{ marginTop: 2 }}
                dbsApp={application}
              >
                💀 Reject Application
              </RejectApplicationButton>
            </>}
          </Alert>
        </Box>
      </Form>
    </Container>
  </>
}

export default Element
