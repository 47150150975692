import React from "react";
import { ActionFunction, redirect } from "react-router-dom";
import { apiClient, apiClientHooks } from "../bootstrapping/InitApiClient";
import { Box, Container, Typography } from "@mui/material";
import { z } from "zod";
import { Form } from "react-router-dom";
import { useForm } from "react-hook-form";
import { CheckboxElement, TextFieldElement } from "react-hook-form-mui";
import { zodResolver } from "@hookform/resolvers/zod";
import { Helmet } from "react-helmet-async";
import { QueryClient } from "@tanstack/react-query";
import { schemas } from "../generated/api/client";
import SingleClickWaitingButton from "../components/SingleClickWaitingButton";
import { TestingInfoBlockFormErrors } from "../components/TestingInfo";

const zFormSchema = schemas.NewCustomer
type FormSchema = z.infer<typeof zFormSchema>

export const Element: React.FC = () => {
  // Build the Form
  const form = useForm<FormSchema>({
    resolver: zodResolver(zFormSchema),
    mode: 'onBlur',
    defaultValues: {
      allow_pay_by_invoice: false,
      sector_healthcare: false,
      enable_applicant_document_selector: true,
      enable_applicant_payment: true,
    },
  })

  // Build the output
  const textFieldElementCommonProps = {
    control: form.control,
    margin: "normal" as const,
    fullWidth: true,
  }

  return <>
    <Helmet>
      <title>New Customer</title>
    </Helmet>
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5" marginBottom={3}>
          New Customer
        </Typography>

        <Form method="post">
          <TextFieldElement {...textFieldElementCommonProps} label="Customer Name" name="name" />

          <TextFieldElement {...textFieldElementCommonProps} label="PIN" name="pin" />

          <TextFieldElement {...textFieldElementCommonProps} label="Telephone" name="telephone" />

          <CheckboxElement
            control={form.control}
            label="Allow customer to pay by invoice."
            name="allow_pay_by_invoice"
            inputProps={{ name: 'allow_pay_by_invoice' }}
          />

          <CheckboxElement
            control={form.control}
            label="Customer operates in the Healthcare Sector."
            name="sector_healthcare"
            inputProps={{ name: 'sector_healthcare' }}
          />

          <CheckboxElement
            control={form.control}
            label="Allow applicants to select documents prior to meeting."
            name="enable_applicant_document_selector"
            inputProps={{ name: 'enable_applicant_document_selector' }}
          />

          <CheckboxElement
            control={form.control}
            label="Allow applicants to pay for applications."
            name="enable_applicant_payment"
            inputProps={{ name: 'enable_applicant_payment' }}
          />

          <TestingInfoBlockFormErrors form={form} />

          <SingleClickWaitingButton
            type="submit"
            fullWidth
            variant="contained"
            color={form.formState.isValid ? 'primary' : 'inherit'}
            onClick={async event => {
              const isValid = await form.trigger()
              if (!isValid) {
                console.warn("Form is not valid", form.formState.errors)
                event.preventDefault()
              }
            }}
            sx={{ mt: 3, mb: 2 }}
          >
            Create
          </SingleClickWaitingButton>
        </Form>
      </Box>
    </Container>
  </>
}

export const action: (queryClient: QueryClient) => ActionFunction = queryClient => async ({ params, request }) => {
  const formData = await request.formData()
  const formObject = Object.fromEntries(formData)
  const FormObjectRetyped = {
    ...formObject,
    // The above checkboxes are either 'true' if checked, or absent if not
    allow_pay_by_invoice: formObject.allow_pay_by_invoice === 'true',
    sector_healthcare: formObject.sector_healthcare === 'true',
    enable_applicant_document_selector: formObject.enable_applicant_document_selector === 'true',
    enable_applicant_payment: formObject.enable_applicant_payment === 'true',
  }
  const newCustomerData = zFormSchema.parse(FormObjectRetyped)

  const createdCustomer = await apiClient.createCustomer(newCustomerData)
  console.log("AddCustomer.action: ", { newCustomerData, createdCustomer })

  // Don't wait for this to complete
  queryClient.invalidateQueries({ queryKey: apiClientHooks.getKeyByAlias('getCustomers') })
  return redirect(`/customers`)
}

export default Element
