import { ReactNode, useState } from "react";
import { Checkbox, FormControlLabel } from "@mui/material";

type UseStateWithCheckboxProps = {
  initialState: boolean,
  label: string,
  disabled?: boolean,
}
const useStateWithCheckbox = (props: UseStateWithCheckboxProps): [boolean, ReactNode, (newValue: boolean) => void] => {
  const [state, setState] = useState<boolean>(props.initialState)
  return [
    state,
    (
      <FormControlLabel
        control={
          <Checkbox
            checked={state}
            onChange={(e) => setState(e.target.checked)}
            disabled={props.disabled}
          />
        }
        label={props.label}
      />
    ),
    setState,
  ]
}

export default useStateWithCheckbox
