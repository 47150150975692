import React, { useEffect, useState } from 'react';
import { Box, Button, IconButton, Modal, Typography } from '@mui/material';
import { z } from 'zod';
import { CheckboxElement, TextFieldElement, useForm } from 'react-hook-form-mui';
import { zodResolver } from '@hookform/resolvers/zod';
import { apiClient, apiClientHooks } from '../bootstrapping/InitApiClient';
import { queryClient } from '../bootstrapping/InitReactQuery';
import CloseIcon from '@mui/icons-material/Close';
import IconButtonWithTooltip, { IconButtonWithTooltipProps } from './IconButtonWithTooltip';
import { schemas } from '../generated/api/client';

type CustomersWithMeta = z.infer<typeof schemas.ReturnType_GetCustomers>
type Customer = CustomersWithMeta['rows'][number]

type EditCustomerIconButtonWithTooltipProps = {
  customer: Customer,
} & IconButtonWithTooltipProps

const EditCustomerIconButtonWithTooltip: React.FC<EditCustomerIconButtonWithTooltipProps> = ({ customer, ...iconButtonWithTooltipProps }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const zLocalFormSchema = schemas.UpdateCustomer
  type LocalFormSchema = z.infer<typeof zLocalFormSchema>
  const form = useForm<LocalFormSchema>({
    resolver: zodResolver(zLocalFormSchema),
    mode: 'onChange',
    defaultValues: customer,
  })

  // When the customer changes, reset the form
  useEffect(() => {
    form.reset(customer)
  }, [form, customer])

  return (
    <div>
      <IconButtonWithTooltip
        {...iconButtonWithTooltipProps}
        onClick={handleOpen}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <IconButton onClick={handleClose} sx={{ position: 'absolute', top: 8, right: 8 }}>
            <CloseIcon />
          </IconButton>
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
            Update Customer
          </Typography>
          <Typography component="body" variant="body1" sx={{ mb: 2 }}>
            {/* {selectedApplicationIds.length} applications are ready to invoice. */}
          </Typography>

          <TextFieldElement
            control={form.control}
            name="name"
            label="Name"
            sx={{ mb: 1 }}
            fullWidth
          />

          <TextFieldElement
            control={form.control}
            name="pin"
            label="PIN"
            sx={{ mb: 1 }}
            fullWidth
          />

          <TextFieldElement
            control={form.control}
            name="telephone"
            label="Telephone"
            sx={{ mb: 1 }}
            fullWidth
          />

          <CheckboxElement
            control={form.control}
            label="Allow customer to pay by invoice."
            name="allow_pay_by_invoice"
            inputProps={{ name: 'allow_pay_by_invoice' }}
          />

          <CheckboxElement
            control={form.control}
            label="Customer operates in the Healthcare Sector."
            name="sector_healthcare"
            inputProps={{ name: 'sector_healthcare' }}
          />

          <CheckboxElement
            control={form.control}
            label="Allow applicants to select documents prior to meeting."
            name="enable_applicant_document_selector"
            inputProps={{ name: 'enable_applicant_document_selector' }}
          />

          <CheckboxElement
            control={form.control}
            label="Allow applicants to pay for applications."
            name="enable_applicant_payment"
            inputProps={{ name: 'enable_applicant_payment' }}
          />

          <Button
            fullWidth
            sx={{ marginTop: 2 }}
            variant="contained"
            color="primary"
            disabled={!form.formState.isValid}
            onClick={async () => {
              const formValues = form.getValues()

              // Find the dirty fields and build the update customer fields object from it
              // zLocalFormSchema ensures that formValues is a valid UpdateCustomer object
              // We reparse here just to ensure an error is thrown in a helpful location
              const dirtyFields = form.formState.dirtyFields
              const dirtyFieldValues = Object.entries(dirtyFields).reduce((acc, [key, isDirty]) => {
                if (isDirty === true) {
                  acc[key] = formValues[key as keyof typeof formValues];
                }
                return acc;
              }, {} as Record<string, any>);
              const updateCustomerFields = zLocalFormSchema.parse(dirtyFieldValues)

              if (Object.keys(updateCustomerFields).length !== 0) {
                await apiClient.updateCustomer(updateCustomerFields, {
                  params: { id: customer.id },
                })

                queryClient.invalidateQueries({ queryKey: apiClientHooks.getKeyByAlias('getCustomers') })
                queryClient.invalidateQueries({ queryKey: apiClientHooks.getKeyByAlias('getCustomerById') })
              }

              handleClose()
            }}
          >
            Update Customer
          </Button>
        </Box>
      </Modal>
    </div>
  )
}

export default EditCustomerIconButtonWithTooltip
