import { useEffect, useState } from "react";
import { Alert, AlertProps, AlertTitle, Box, Container, FormGroup, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import useTestApiKey from "../hooks/useTestApiKey";
import { apiClient } from "../bootstrapping/InitApiClient";
import SingleClickWaitingButton from "../components/SingleClickWaitingButton";
import EBulkApplicationsBatchTable from "../components/EBulkApplicationsBatchTable";
import { JSONTree } from 'react-json-tree';
import { z } from "zod";
import { schemas } from "../generated/api/client";
import BatchDryRunButton from "../components/BatchDryRunButton";
import useStateWithCheckbox from "../hooks/useStateWithCheckbox";

const GetApplicationsAwaitingSubmission: React.FC<Omit<AlertProps, 'severity'>> = ({ ...alertProps }) => {
  const [applicationsAwaitingSubmission, setApplicationsAwaitingSubmission] = useState<number | null>(null)

  return (
    <Alert {...alertProps} severity={applicationsAwaitingSubmission == null ? 'info' : 'success'}>
      <AlertTitle>Applications Awaiting Submission to the DBS</AlertTitle>
      <Typography variant="h5" align="center" sx={{ marginBottom: 2 }}>
        {applicationsAwaitingSubmission == null ? '...' : applicationsAwaitingSubmission}
      </Typography>
      <Box display="flex" justifyContent="flex-end">
        <BatchDryRunButton
          variant="contained"
          color="secondary"
          sx={{ marginRight: 2 }}
        >
          Dry Run
        </BatchDryRunButton>
        <SingleClickWaitingButton
          variant="contained"
          onClick={async () => {
            console.log("Updating the applications awaiting submission to the DBS")
            const applications = await apiClient.getEBulkApplications({ queries: { columnFilters: [{ id: 'status', value: 'awaiting_submission_to_DBS' }] }, headers: {} })
            setApplicationsAwaitingSubmission(applications.meta.totalRowCount)
          }}
        >
          Count Applications
        </SingleClickWaitingButton>
      </Box>
    </Alert>
  )
}

type SyncResponse = z.infer<typeof schemas.ReturnType_EBulkApplicationsBatchSync>

const showCountOf = (response: SyncResponse | null, key: keyof SyncResponse): string => {
  if (response == null) return ''
  const count = response[key]?.batchIds?.length
  return count == null
    ? ''
    : ` (${count} ${count === 1 ? 'batch' : 'batches'})`
}

const RunSync: React.FC<Omit<AlertProps, 'severity'>> = ({ ...alertProps }) => {
  const [response, setResponse] = useState<SyncResponse | null>(null)

  const [CRB01, CRB01Checkbox] = useStateWithCheckbox({ initialState: false, label: `Upload CRB01${showCountOf(response, 'CRB01')}` })
  const [CRB02, CRB02Checkbox] = useStateWithCheckbox({ initialState: true, label: `Download CRB02${showCountOf(response, 'CRB02')}` })
  const [CRB03, CRB03Checkbox] = useStateWithCheckbox({ initialState: true, label: `Download CRB03${showCountOf(response, 'CRB03')}` })
  const [CRB04, CRB04Checkbox] = useStateWithCheckbox({ initialState: true, label: `Download CRB04${showCountOf(response, 'CRB04')}` })

  return (
    <Alert {...alertProps} severity={response == null ? 'info' : 'success'}>
      <AlertTitle>Run E-Bulk Batch Sync</AlertTitle>
      <FormGroup>
        {CRB01Checkbox}
        {CRB02Checkbox}
        {CRB03Checkbox}
        {CRB04Checkbox}
      </FormGroup>

      <Box display="flex" justifyContent="flex-end">
        <SingleClickWaitingButton
          variant="contained"
          onClick={async () => {
            console.log("Checking the E-Bulk Connections: ", { CRB01, CRB02, CRB03, CRB04 })
            const response = await apiClient.eBulkApplicationsBatchSync({
              CRB01,
              CRB02,
              CRB03,
              CRB04,
            })
            setResponse(response)
          }}
        >
          Run Sync
        </SingleClickWaitingButton>
      </Box>
    </Alert>
  )
}

const RunProcess: React.FC<Omit<AlertProps, 'severity'>> = ({ ...alertProps }) => {
  return (
    <Alert {...alertProps} severity="info">
      <AlertTitle>Run E-Bulk Batch Processing</AlertTitle>

      <Box display="flex" justifyContent="flex-end">
        <SingleClickWaitingButton
          variant="contained"
          onClick={async () => {
            console.log("Process E-Bulk Batches")
            await apiClient.processEBulkApplicationsBatches(undefined)
          }}
        >
          Process Batches
        </SingleClickWaitingButton>
      </Box>
    </Alert>
  )
}

const TestConnectionsCheck: React.FC = () => {
  const { testApiKey } = useTestApiKey()
  const [response, setResponse] = useState<any | null>(null)

  const [httpDirect, HttpDirectCheckbox] = useStateWithCheckbox({ initialState: true, label: 'HTTP Direct' })
  const [httpProxy, HttpProxyCheckbox] = useStateWithCheckbox({ initialState: true, label: 'HTTP Proxy' })
  const [ftpConnect, FtpConnectCheckbox] = useStateWithCheckbox({ initialState: true, label: 'FTP Connect' })
  const [ftpList, FtpListCheckbox] = useStateWithCheckbox({ initialState: false, label: 'FTP List' })
  const [ftpDownload, FtpDownloadCheckbox, setFtpDownload] = useStateWithCheckbox({ initialState: false, label: 'FTP Download (requires FTP List)', disabled: !ftpList })
  const [ftpDelete, FtpDeleteCheckbox, setFtpDelete] = useStateWithCheckbox({ initialState: false, label: 'FTP Delete (required FTP Download)', disabled: !ftpDownload })
  const [ftpUpload, FtpUploadCheckbox] = useStateWithCheckbox({ initialState: false, label: 'FTP Upload' })

  useEffect(() => {
    if (!ftpList) setFtpDownload(false)
    if (!ftpDownload) setFtpDelete(false)
  }, [ftpList, ftpDownload, setFtpDownload, setFtpDelete])

  return (
    <Alert severity={response == null ? 'info' : 'success'}>
      <AlertTitle>E-Bulk Connection Check</AlertTitle>
      {response && <JSONTree data={response} />}

      <FormGroup>
        {HttpDirectCheckbox}
        {HttpProxyCheckbox}
        {FtpConnectCheckbox}
        {FtpListCheckbox}
        {FtpDownloadCheckbox}
        {FtpDeleteCheckbox}
        {FtpUploadCheckbox}
      </FormGroup>

      <Box display="flex" justifyContent="flex-end">
        <SingleClickWaitingButton
          variant="contained"
          onClick={async () => {
            console.log("Checking the E-Bulk Connections")
            const response = await apiClient.testConnectionsCheck({
              queries: {
                httpDirect,
                httpProxy,
                ftpConnect,
                ftpList,
                ftpDownload,
                ftpDelete,
                ftpUpload,
              },
              headers: {
                'X-Test-API-Key': testApiKey,
              }
            })
            setResponse(response)
          }}
        >
          List Files
        </SingleClickWaitingButton>
      </Box>
    </Alert>
  )
}

export const Element: React.FC = () => {
  const isTestingEnabled = process.env.REACT_APP_TESTING === 'true'

  return <>
    <Helmet>
      <title>E-Bulk Batches</title>
    </Helmet>
    <Container component="main">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h4" gutterBottom>
          E-Bulk Batches
        </Typography>

        <GetApplicationsAwaitingSubmission sx={{ marginBottom: 3 }} />

        <RunSync sx={{ marginBottom: 3 }} />

        <RunProcess sx={{ marginBottom: 3 }} />

        <EBulkApplicationsBatchTable />

        {isTestingEnabled && <>
          <Typography variant="h4" gutterBottom>
            Testing
          </Typography>

          <TestConnectionsCheck />
        </>}
      </Box>
    </Container>
  </>
}

export default Element
