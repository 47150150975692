import { Box, Button, Modal, Typography, IconButton, ButtonProps } from "@mui/material"
import { Close as CloseIcon } from '@mui/icons-material'
import { apiClient } from "../bootstrapping/InitApiClient"
import { useState } from "react"
import XMLViewer from "react-xml-viewer"
import SingleClickWaitingButton from "./SingleClickWaitingButton"

const BatchDryRunButton: React.FC<Omit<ButtonProps, 'onclick'>> = ({ children, ...buttonProps }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [xml, setXml] = useState<string>('<initialising/>')

  const loadDataThenOpen = async () => {
    const response = await apiClient.eBulkApplicationsBatchDryRun(undefined)
    setXml(response.xml)
    handleOpen()
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height: '80%',
    overflow: 'auto',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  return <>
    <SingleClickWaitingButton {...buttonProps} onClick={loadDataThenOpen}>{children}</SingleClickWaitingButton>
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
          Batch Dry Run
        </Typography>
        <XMLViewer xml={xml} />
        <Button
          variant="text"
          color="primary"
          onClick={handleClose}
          sx={{ ml: 3, mt: 2 }}
        >
          Close
        </Button>
      </Box>
    </Modal>
  </>
}

export default BatchDryRunButton
