import React, { useCallback, useMemo, useState } from 'react';
import {
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_Row,
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import {
  keepPreviousData,
  useQuery,
} from '@tanstack/react-query'
import { apiClient, apiClientHooks } from "../bootstrapping/InitApiClient";
import { Helmet } from "react-helmet-async";
import { z } from 'zod';
import { schemas } from '../generated/api/client';
import { isSomething } from '../utils/utils';
import { isAxiosError } from 'axios';
import { useAppTable } from '../components/AppTable';
import { getTableFilterSelectOptions, labelsForEBulkApplicationPaymentMethod, labelsForEBulkApplicationStatus } from '../constants/enums';
import { Alert, AlertProps, AlertTitle, Box, Container, FormGroup, Typography } from '@mui/material';
import IconButtonWithTooltip from '../components/IconButtonWithTooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import AddIcon from '@mui/icons-material/Add';
import MinusIcon from '@mui/icons-material/Remove';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useNavigate } from 'react-router-dom';
import RejectIconButton from '../components/RejectIconButton';
import { useProfileAssured } from '../bootstrapping/InitRequireProfile';
import CompleteChangesIconButton from '../components/CompleteChangesIconButton';
import ErrorApplicationIconButton from '../components/ErrorApplicationIconButton';
import ResendApplicationIconButton from '../components/ResendApplicationIconButton';
import { JSONTree } from 'react-json-tree';
import { computeDriverLicenceNumberPrefix } from '../logic/rulesDriverLicenceNumber';
import SingleClickWaitingButton from '../components/SingleClickWaitingButton';
import useStateWithCheckbox from '../hooks/useStateWithCheckbox';
import TrackWithDBSIconButton from '../components/TrackWithDBSIconButton';
import EResultIconButton from '../components/EResultIconButton';
import ReturnToCountersigningApplicationIconButton from '../components/ReturnToCountersigningApplicationIconButton';

type EBulkApplicationsWithMeta = z.infer<typeof schemas.ReturnType_GetEBulkApplications>
type EBulkApplication = EBulkApplicationsWithMeta['rows'][number]

const ReconcileStripeCheckouts: React.FC<Omit<AlertProps, 'severity'>> = ({ ...alertProps }) => {
  const [response, setResponse] = useState<{} | null>(null)

  const [update_checkout_sessions, UpdateCheckoutSessionsCheckbox] = useStateWithCheckbox({ initialState: true, label: `Update Checkout Sessions` })
  const [check_payment_status, CheckPaymentStatusCheckbox] = useStateWithCheckbox({ initialState: true, label: `Check Payment Status` })
  const [action_updates, ActionUpdatesCheckbox] = useStateWithCheckbox({ initialState: false, label: `Action Updates (default off: Dry Run)` })

  return (
    <Alert {...alertProps} severity={response == null ? 'info' : 'success'}>
      <AlertTitle>Reconcile Stripe Checkouts</AlertTitle>
      {response && <JSONTree data={response} shouldExpandNodeInitially={() => true} />}

      <FormGroup>
        {UpdateCheckoutSessionsCheckbox}
        {CheckPaymentStatusCheckbox}
        {ActionUpdatesCheckbox}
      </FormGroup>

      <Box display="flex" justifyContent="flex-end">
        <SingleClickWaitingButton
          variant="contained"
          onClick={async () => {
            console.log("Reconciling Stripe Checkouts: ", { update_checkout_sessions, check_payment_status, action_updates })
            const response = await apiClient.reconcileStripeCheckouts({
              update_checkout_sessions,
              check_payment_status,
              action_updates,
            })
            setResponse(response)
          }}
        >
          Execute
        </SingleClickWaitingButton>
      </Box>
    </Alert>
  )
}

const DetailPanel = ({ row }: { row: MRT_Row<EBulkApplication> }) => {
  const useGetTransitionLogForEBulkApplicationByID = apiClientHooks.useGetTransitionLogForEBulkApplicationByID({
    params: {
      id: row.original.id,
    },
  })
  const transitionLog = useGetTransitionLogForEBulkApplicationByID.data
  console.log("DetailPanel: ", { row, useGetTransitionLogForEBulkApplicationByID })

  const driverLicenceNumberPrefix = computeDriverLicenceNumberPrefix(row.original.data)

  return <>
    <Typography variant="h6">Application</Typography>
    <Typography variant="body2">
      <JSONTree data={row.original} />
    </Typography>
    <Typography variant="h6">Validation</Typography>
    <Typography variant="body2">
      <strong>Expected Driver Licence Number Prefix:</strong>
      <pre>{driverLicenceNumberPrefix}</pre>
    </Typography>
    <Typography variant="h6">Transaction History</Typography>
    <Typography variant="body2">
      {(transitionLog?.changes ?? []).length === 0 && <em>No changes</em>}
      {(transitionLog?.changes ?? []).map((change, index) => (
        <div key={index}>
          <strong>{change.transitioned_at}</strong>: {change.transitioned_from} → {change.transitioned_to}<br />
          {change.transitioned_metadata && <JSONTree data={change.transitioned_metadata} />}
        </div>
      ))}
    </Typography>
    <Typography variant="h6">Application Batches</Typography>
    <Typography variant="body2">
      <em>TODO: Load Batches with this Application ID, show as the batches table</em>
    </Typography>
  </>;
};

export const Element: React.FC = () => {
  const navigate = useNavigate()

  const profile = useProfileAssured()
  const can_countersign = profile.countersignatory_number != null

  // Fetch the status from the querystring (if present)
  const q_status = new URLSearchParams(window.location.search).get('status') ?? undefined
  const columnFilterInitialValues = useCallback((defaults: MRT_ColumnFiltersState) => [
    ...defaults,
    { id: 'status', value: q_status },
  ], [q_status])

  const {
    tableConfig,
  } = useAppTable<EBulkApplication, EBulkApplicationsWithMeta, Error | null>({
    columnFilterInitialValues,
    useTableQuery: ({ columnFilters, globalFilter, pagination, sorting }) =>
      useQuery<EBulkApplicationsWithMeta>({
        queryKey: [
          ...apiClientHooks.getKeyByAlias('getEBulkApplications'),
          columnFilters, //refetch when columnFilters changes
          globalFilter ?? '', //refetch when globalFilter changes
          pagination.pageIndex, //refetch when pagination.pageIndex changes
          pagination.pageSize, //refetch when pagination.pageSize changes
          sorting, //refetch when sorting changes
        ],
        queryFn: async () => {
          // Build the sort by string, and ensure it meets the API schema
          const sort = (() => {
            const firstSort = sorting[0]
            if (!firstSort) return undefined

            const compoundSort = `${firstSort.id} ${firstSort.desc ? 'desc' : 'asc'}`
            return schemas.Sort_GetEBulkApplications.parse(compoundSort)
          })()

          try {
            const result = await apiClient.getEBulkApplications({
              queries: {
                page_index: `${pagination.pageIndex}`,
                page_size: `${pagination.pageSize}`,
                sort,
                globalFilter: globalFilter.length > 0 ? globalFilter : undefined,
                columnFilters: schemas.ColumnFilters_GetEBulkApplications.parse(
                  columnFilters
                    .map(filter => {
                      // Check ID is a valid column
                      const parseResult = schemas.ColumnFilters_GetJobs.element.safeParse(filter)
                      if (!parseResult.success && parseResult.error.errors.find(e => e.path[0] === 'id')) {
                        console.error("Invalid column ID for filter: ", filter)
                        return undefined
                      }

                      // Transform Enum Filters

                      // Transform Boolean Filters
                      if (filter.id === 'active' || filter.id === 'allowPayByInvoice') {
                        const valueAsStringBoolean = z.union([z.literal('true'), z.literal('false')]).safeParse(filter.value)
                        if (!valueAsStringBoolean.success) {
                          console.error("Invalid Boolean Filter (value should be string 'true' or 'false'): ", filter)
                          return undefined
                        }

                        return {
                          id: filter.id,
                          value: valueAsStringBoolean.data === 'true' ? true : false,
                        }
                      }

                      // Transform Date Range Filters
                      if (filter.id === 'createdAt' || filter.id === 'updatedAt') {
                        const values = z.array(z.date().optional()).safeParse(filter.value)
                        if (!values.success || values.data.length !== 2) {
                          console.error("Invalid date range filter: ", filter)
                          return undefined
                        }
                        if (values.data[0] === undefined && values.data[1] === undefined) {
                          // Nothing to filter on!
                          return undefined
                        }

                        return {
                          id: filter.id,
                          from: values.data[0]?.toISOString(),
                          to: values.data[1]?.toISOString(),
                        }
                      }

                      // Everything else is just a text filter
                      var parsedValue = z.string().safeParse(filter.value)
                      if (!parsedValue.success) {
                        console.error("Invalid text filter: ", filter)
                        return undefined
                      }

                      return {
                        id: filter.id,
                        value: parsedValue.data,
                      }
                    })
                    .filter(isSomething)
                )
              },
            })

            console.log("Applications.Element.queryFn: ", { result })
            return result
          } catch (error) {
            if (!isAxiosError(error)) {
              console.error("Error fetching jobs (non-Axios): ", error)
              throw error
            }

            const parsedValue = schemas.ErrorResponse.safeParse(error.response?.data)
            if (!parsedValue.success) {
              console.error("Error fetching jobs (unknown error): ", error)
              throw error
            }

            console.error("Error fetching jobs: ", parsedValue.data.error)
            return Promise.reject(parsedValue.data.error)
          }
        },
        placeholderData: keepPreviousData, //don't go to 0 rows when refetching or paginating to next page
      }),
    columns: useMemo<MRT_ColumnDef<EBulkApplication>[]>(() => [
      {
        accessorKey: 'status',
        header: 'Status',
        size: 150,
        enableSorting: true,
        enableColumnFilter: true,
        Cell: ({ cell }) => labelsForEBulkApplicationStatus[cell.getValue() as keyof typeof labelsForEBulkApplicationStatus],
        filterVariant: 'select',
        filterSelectOptions: getTableFilterSelectOptions(labelsForEBulkApplicationStatus),
        _visible: q_status == null,
      },
      {
        accessorKey: 'test_application',
        accessorFn: (originalRow) => (originalRow.test_application ? 'true' : 'false'), //must be strings
        header: 'Test Application',
        size: 150,
        enableSorting: false,
        enableColumnFilter: false,
        Cell: ({ cell }) => cell.getValue() === 'true' ? '✅' : '❌',
        _visible: false,
      },
      {
        accessorKey: 'profile_given_name',
        header: 'Given Name',
        size: 150,
        enableSorting: true,
        enableColumnFilter: true,
      },
      {
        accessorKey: 'profile_family_name',
        header: 'Family Name',
        size: 150,
        enableSorting: true,
        enableColumnFilter: true,
      },
      {
        accessorKey: 'customer_id',
        header: 'Customer ID',
        size: 150,
        enableSorting: false,
        enableColumnFilter: true,
        _visible: false,
      },
      {
        accessorKey: 'customer_name',
        header: 'Customer Name',
        size: 150,
        enableSorting: false,
        enableColumnFilter: true,
      },
      {
        accessorKey: 'job_id',
        header: 'Job ID',
        size: 150,
        enableSorting: false,
        enableColumnFilter: true,
        _visible: false,
      },
      {
        accessorKey: 'job_role',
        header: 'Job Role',
        size: 150,
        enableSorting: true,
        enableColumnFilter: true,
      },
      {
        accessorKey: 'payment_at',
        header: 'Payment At',
        size: 150,
        enableSorting: true,
        filterVariant: 'datetime-range',
        muiFilterDateTimePickerProps: {
          format: 'dd-MMM-yyyy HH:mm',
        },
        _visible: false,
      },
      {
        accessorKey: 'payment_method',
        header: 'Payment Method',
        size: 150,
        enableSorting: true,
        enableColumnFilter: true,
        Cell: ({ cell }) => labelsForEBulkApplicationPaymentMethod[cell.getValue() as keyof typeof labelsForEBulkApplicationPaymentMethod],
        filterVariant: 'select',
        filterSelectOptions: getTableFilterSelectOptions(labelsForEBulkApplicationPaymentMethod),
        _visible: false,
      },
      {
        accessorKey: 'payment_reference',
        header: 'Payment Reference',
        size: 150,
        enableSorting: true,
        enableColumnFilter: true,
        _visible: false,
      },
      {
        accessorKey: 'external_invoice_reference',
        header: 'External Invoice Reference',
        size: 150,
        enableSorting: false,
        enableColumnFilter: false,
        _visible: false,
      },
      {
        accessorKey: 'gdpr_consent',
        accessorFn: (originalRow) => (originalRow.gdpr_consent ? 'true' : 'false'), //must be strings
        header: 'GDPR Consent',
        size: 150,
        enableSorting: true,
        enableColumnFilter: true,
        Cell: ({ cell }) => cell.getValue() === 'true' ? '✅' : '❌',
        filterVariant: 'checkbox',
        _visible: false,
      },
      {
        accessorKey: 'dbs_enumber',
        header: 'E-Number',
        size: 150,
        enableSorting: false,
        enableColumnFilter: true,
        Cell: ({ cell }) =>
          <TrackWithDBSIconButton
            showENumber
            dbs_enumber={cell.row.original.dbs_enumber}
            DateOfBirth={cell.row.original.data.DateOfBirth}
            title="Track with DBS"
            icon={OpenInNewIcon}
            type='submit'
            color='primary'
          />,
        _visible: true,
      },
      {
        accessorKey: 'data.DateOfBirth',
        header: 'Date of Birth',
        size: 150,
        enableSorting: false,
        enableColumnFilter: true,
        _visible: true,
      },
    ], [q_status]),
  })

  const table = useMaterialReactTable({
    ...tableConfig,
    enableRowActions: true,
    renderRowActions: ({ row }) => (
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        {row.original.active === true
          ? <>
            {/* TODO: Create the RejectIconButton copy from WithdrawalButton in applicant  */}
            {/* If the Application has not been sent to the DBS yet (or errored from the DBS), show a button to reject the application */}
            {['draft', 'awaiting_customer_approval', 'awaiting_payment', 'needs_changes', 'awaiting_countersigning', 'error_from_DBS'].includes(row.original.status) && (
              <RejectIconButton
                title="Reject"
                icon={DeleteIcon}
                color='error'
                dbsApp={row.original}
              />
            )}
            {/* If the Application is in the Customer Approval stage, show a button to approve the application (go to: `:id/meeting` relatively) */}
            {row.original.status === 'awaiting_customer_approval' && (
              <IconButtonWithTooltip
                title="Review Application"
                icon={HowToRegIcon}
                color='primary'
                onClick={() => { navigate(`${row.original.id}/meeting`) }}
              />
            )}
            {/* If the Application is in the Awaiting Payment stage, show a button to approve the application (go to: `:id/payment` relatively) */}
            {row.original.status === 'awaiting_payment' && (
              <IconButtonWithTooltip
                title="Pay for Application"
                icon={ShoppingCartIcon}
                color='primary'
                onClick={() => { navigate(`${row.original.id}/payment`) }}
              />
            )}
            {/* If the Application is in the Awaiting Countersigning stage, show a button to countersign the application (go to: `:id/countersign` relatively) */}
            {/* If my profile cannot countersign then disabled it as red */}
            {row.original.status === 'awaiting_countersigning' && (
              <IconButtonWithTooltip
                title={can_countersign ? 'Countersign Application' : 'You cannot countersign'}
                icon={HowToRegIcon}
                color={can_countersign ? 'primary' : 'error'}
                onClick={() => { can_countersign && navigate(`${row.original.id}/countersign`) }}
                disabled={!can_countersign}
              />
            )}
            {/* If the Application is in the Awaiting Submission to DBS stage, show a button to push the application back into awaiting_countersigning */}
            {row.original.status === 'awaiting_submission_to_DBS' && (
              <ReturnToCountersigningApplicationIconButton
                title="Return to Countersigning"
                icon={RotateLeftIcon}
                color='warning'
                dbsApp={row.original}
              />
            )}
            {/* If the Application is in the Needs Changes stage, show a button to push the application back into awaiting_countersigning */}
            {row.original.status === 'needs_changes' && (
              <CompleteChangesIconButton
                title="Return to Countersigning"
                icon={DoneOutlineIcon}
                color='primary'
                dbsApp={row.original}
              />
            )}
            {/* If the Application is in the Awaiting Response from DBS stage, allow it to be marked as errored */}
            {row.original.status === 'awaiting_response_from_DBS' && (
              <ErrorApplicationIconButton
                title="Mark as Errored"
                icon={ErrorOutlineIcon}
                color='error'
                dbsApp={row.original}
              />
            )}
            {/* If the Application is in the Error from DBS stage, allow it to be resent in the next batch */}
            {row.original.status === 'error_from_DBS' && (
              <ResendApplicationIconButton
                title="Resend Application to DBS"
                icon={RotateRightIcon}
                color='primary'
                dbsApp={row.original}
              />
            )}
            {/* Show the E-Result */}
            {row.original.dbs_eresult != null && (
              <EResultIconButton
                title="Show E-Result"
                icon={WorkspacePremiumIcon}
                color='success'
                dbsApp={row.original}
              />
            )}
          </>
          : null}
      </Box>
    ),
    muiExpandButtonProps: ({ row }) => ({
      children: row.getIsExpanded() ? <MinusIcon /> : <AddIcon />,
    }),
    renderDetailPanel: props => <DetailPanel {...props} />,
  });

  return <>
    <Helmet>
      <title>Applications</title>
    </Helmet>
    <Container component="main" maxWidth={false}>
      <Typography component="h1" variant="h3" marginTop={3} marginBottom={3}>Applications</Typography>
      <MaterialReactTable table={table} />
      {q_status === 'awaiting_payment' && <ReconcileStripeCheckouts sx={{ marginTop: 3 }} />}
    </Container>
  </>
}
